import { CodeOptionsType } from '../types/oneDragTowType'

export const options: CodeOptionsType = [
  {
    mainTitle: 'cuttingDock.other.title',
    code: [
      {
        title: 'cuttingDock.autoStockCode',
        data: '',
        field: 'secondAutoSendStockCode',
      },
      {
        title: 'cuttingDock.pauseCode',
        data: '',
        field: 'secondPauseCode',
      },
    ],
  },
  {
    mainTitle: 'cuttingDock.stationStart',
    code: [
      {
        title: 'cuttingDock.stationStartCode1',
        data: '',
        field: 'secondOddBeginCode',
      },
      {
        title: 'cuttingDock.stationStartCode2',
        data: '',
        field: 'secondEvenBeginCode',
        isStationTwo: true,
      },
    ],
  },
  {
    mainTitle: 'cuttingDock.frontBackStart',
    code: [
      {
        title: 'cuttingDock.frontStartCode',
        data: '',
        field: 'secondBeginCodeF',
      },
      {
        title: 'cuttingDock.backStartCode',
        data: '',
        field: 'secondBeginCodeB',
      },
    ],
  },
  {
    mainTitle: 'cuttingDock.frontBackEnd',
    code: [
      {
        title: 'cuttingDock.frontEndCode',
        data: '',
        field: 'secondEndCodeF',
      },
      {
        title: 'cuttingDock.backEndCode',
        data: '',
        field: 'secondEndCodeB',
      },
    ],
  },
  {
    mainTitle: 'common.roll',
    code: [
      {
        title: 'cuttingDock.plankRollCode1',
        data: '',
        field: 'secondRollCode',
      },
      {
        title: 'cuttingDock.plankRollCode2',
        data: '',
        field: 'secondEvenRollCode',
        isStationTwo: true,
      },
    ],
  },
  {
    mainTitle: 'cuttingDock.stationEnd',
    code: [
      {
        title: 'cuttingDock.stationEndCode1',
        data: '',
        field: 'secondOddEndCode',
      },
      {
        title: 'cuttingDock.stationEndCode2',
        data: '',
        field: 'secondEvenEndCode',
        isStationTwo: true,
      },
    ],
  },
]
