import { translate } from '@/util/commonFun'

export const mentaojiConfig = {
  propList: [
    {
      prop: 'serial_num',
      label: 'cuttingDock.knives.number',
      minWidth: 50,
      slotName: 'serial_num',
    },
    {
      prop: 'saw_width',
      label: 'cuttingDock.knives.diameter',
      minWidth: 100,
      slotName: 'saw_width',
      interactionType: 'textarea',
    },
    {
      prop: 'saw_direction',
      label: 'cuttingDock.knives.sawType',
      minWidth: 120,
      slotName: 'saw_direction',
    },
    {
      prop: 'cut_from',
      label: 'cuttingDock.knives.cutDirection',
      minWidth: 150,
      slotName: 'cut_from',
    },
    {
      prop: 'move_speed',
      label: 'cuttingDock.knives.moveSpeed',
      minWidth: 100,
      slotName: 'move_speed',
      interactionType: 'textarea',
    },
    {
      prop: 'cut_speed',
      label: 'cuttingDock.knives.cutSpeed',
      minWidth: 100,
      slotName: 'cut_speed',
      interactionType: 'textarea',
    },
    {
      prop: 'start_code',
      label: 'cuttingDock.knives.startCode',
      minWidth: 120,
      slotName: 'start_code',
      interactionType: 'textarea',
    },
    {
      prop: 'end_code',
      label: 'cuttingDock.knives.endCode',
      minWidth: 120,
      slotName: 'end_code',
      interactionType: 'textarea',
    },
    {
      prop: 'saw_down_code',
      label: 'cuttingDock.knives.sawDownCode',
      minWidth: 100,
      slotName: 'saw_down_code',
      interactionType: 'textarea',
    },
    {
      prop: 'saw_up_code',
      label: 'cuttingDock.knives.sawUpCode',
      minWidth: 100,
      slotName: 'saw_up_code',
      interactionType: 'textarea',
    },
    {
      prop: 'abscissa',
      label: 'cuttingDock.knives.abscissa',
      minWidth: 100,
      slotName: 'abscissa',
      interactionType: 'textarea',
    },
    {
      prop: 'ordinate',
      label: 'cuttingDock.knives.ordinate',
      minWidth: 100,
      slotName: 'ordinate',
      interactionType: 'textarea',
    },
  ],
  showIndexColumn: false,
}

export const knifeStoreConfigList = [
  // 编号
  {
    label: 'cuttingDock.knives.number',
    props: 'desc',
    width: 70,
    isCheck: false,
  },
  // 名称
  {
    label: 'cuttingDock.knives.name',
    props: 'name',
    width: 90,
    isCheck: false,
  },
  // 刀直径
  {
    label: 'cuttingDock.knives.diameter',
    props: 'diameter',
    width: 90,
    isCheck: false,
  },
  // 开料
  {
    label: 'cuttingDock.knives.cutting',
    props: 'cutable',
    width: 70,
    isCheck: false,
  },
  // 用途
  { label: 'cuttingDock.knives.use', props: 'use', width: 140, isCheck: false },
  // Z轴快速移动速度
  {
    label: 'cuttingDock.knives.moveZF',
    props: 'moveZF',
    width: 130,
    isCheck: true,
  },
  // Z轴加工速度
  {
    label: 'cuttingDock.knives.cutZF',
    props: 'cutZF',
    width: 140,
    isCheck: true,
  },
  // XY轴快速移动速度
  {
    label: 'cuttingDock.knives.moveF',
    props: 'moveF',
    width: 140,
    isCheck: true,
  },
  // XY轴加工速度
  {
    label: 'cuttingDock.knives.cutF',
    props: 'cutF',
    width: 140,
    isCheck: true,
  },
  // 换刀开始代码
  {
    label: 'cuttingDock.knives.startCode',
    props: 'startCode',
    width: 140,
    isCheck: false,
  },
  // 换刀结束代码
  {
    label: 'cuttingDock.knives.stopCode',
    props: 'stopCode',
    width: 140,
    isCheck: false,
  },
]

export const gangDrillTableConfigList = [
  // 钻头直径
  { label: 'cuttingDock.drillSettings.toolTable.diameter', prop: 'diameter' },
  // 'X偏移'
  { label: 'cuttingDock.drillSettings.toolTable.xOffset', prop: 'xOffset' },
  // Y偏移
  { label: 'cuttingDock.drillSettings.toolTable.yOffset', prop: 'yOffset' },
  // Z轴快速移动速度
  { label: 'cuttingDock.drillSettings.toolTable.moveZF', prop: 'moveZF' },
  // Z轴加工速度
  { label: 'cuttingDock.drillSettings.toolTable.feedRate', prop: 'feedRate' },
  // XY轴快速移动速度
  { label: 'cuttingDock.drillSettings.toolTable.moveF', prop: 'moveF' },
]
