import { translate } from '@/util/translate'

export const languageMap = new Map([
  ['存在孔槽找不到刀具', 'arrangedPage.knivesModalTitle'],
  [
    '无法下载NC，系统检测存在以下孔槽找不到刀具',
    'arrangedPage.cannotDownloadByKnivesErr',
  ],
  ['查看问题小板', 'arrangedPage.viewPart'],
  ['正在保存历史文件', 'common.savingHistory'],
  ['保存历史文件成功', 'common.saveHistorySuccess'],
  ['保存历史文件失败', 'common.saveHistoryFail'],
])
// 翻译文本
export function translateLang(
  key: string,
  payload?: Record<string, any> | Array<any>
) {
  return translate(key, languageMap, payload)
}
