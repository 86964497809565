<template>
  <div class="labelling-setting h100">
    <div class="h100 y-auto">
      <div class="head-title">
        <span>{{ $t('cuttingDock.labelingSettings.output') }}</span>
        <a-switch
          default-checked
          v-model="formData.labelingMachine"
          id="engraving_setting_labelMachine_switch"
        ></a-switch>
      </div>
      <div v-show="formData.labelingMachine">
        <div class="label-file-format mt16">
          <span class="head-title">{{
            $t('cuttingDock.labelingSettings.specification')
          }}</span>
          <!-- <div class="radio-box">
            <a-radio-group v-model="formData.labelFormat">
              <a-radio v-for="(item, index) in fileFormatList" :key="index" :value="item.value">{{ item.name }}</a-radio>
            </a-radio-group>
          </div> -->
          <div class="radio-box">
            <a-select
              style="width: 180px"
              v-model="formData.labelFormat"
              @change="changeLabelingFormat"
              id="engraving_setting_labelFormat_select"
              show-search
              showArrow
              :filter-option="
                (input, option) =>
                  option.componentOptions.children[0].text
                    .toLowerCase()
                    .trim()
                    .includes(input.toLowerCase())
              "
            >
              <a-select-option
                v-for="(label, index) in allLabelingList"
                :key="label.labelFormat"
                :value="label.labelFormat"
                :id="`engraving_setting_labelFormat_option_${index}`"
                :title="label.modelName"
                class="all-labeling"
              >
                {{ label.modelName }}
              </a-select-option>
            </a-select>
            <span v-if="labelNotOnline" class="not-online-info">{{
              $t('cuttingDock.labelingSettings.templateOffline')
            }}</span>
          </div>
        </div>
        <div class="auto-feeding-box">
          <span class="mr8">{{
            $t('cuttingDock.labelingSettings.codeFrontGenerate')
          }}</span
          ><a-checkbox
            v-model="formData.label_after_send_stock"
            id="engraving_setting_label_after_send_stock_checkbox"
          ></a-checkbox>
        </div>
        <!-- 自动贴标设置 -->
        <div class="auto-label-setting">
          <div class="flex title">
            <span class="fs18 color-0 bold">{{
              $t('cuttingDock.labelingSettings.autolabel')
            }}</span>
            <span class="inline-block ml5 auto-label-tip"
              >({{ $t('cuttingDock.labelingSettings.customTemp') }})</span
            >
          </div>
          <a-radio-group
            v-model="formData.labelingOrder"
            @change="handleChangeRadio"
            id="engraving_setting_label_sort_radio"
            class="ml20"
          >
            <a-radio value="cutOrder" :style="radioStyle">
              {{ $t('cuttingDock.labelingSettings.cuttingLabelOder') }}
            </a-radio>
            <a-radio value="toTop" :style="radioStyle">
              {{ $t('cuttingDock.labelingSettings.labelToUp') }}
            </a-radio>
            <a-radio value="toBottom" :style="radioStyle">
              {{ $t('cuttingDock.labelingSettings.labelToDown') }}
            </a-radio>
          </a-radio-group>
        </div>
        <!-- 孔槽避让 -->
        <div class="flex labeling-dodge">
          <span class="fs18 color-0 bold mr16">{{
            $t('cuttingDock.labelingSettings.labelDodge')
          }}</span>
          <a-switch
            v-model="formData.labelingDodge"
            id="engraving_setting_dodge_switch"
          ></a-switch>
        </div>
        <div>
          <span class="head-title labeling-setting">{{
            $t('cuttingDock.labelingSettings.rollLabelling')
          }}</span>
          <div class="auto-feeding-box">
            <span class="mr8">{{
              $t(
                'cuttingDock.labelingSettings.flipSetting.engravingFlipProcess'
              )
            }}</span
            ><a-checkbox v-model="formData.labelOverturn"></a-checkbox>
            <i class="tip mr10 fs13"
              >（{{
                $t('cuttingDock.labelingSettings.flipSetting.flipTip')
              }}）</i
            >
          </div>
        </div>
        <div class="tag-setting">
          <span class="head-title"
            >{{ $t('cuttingDock.labelingSettings.rotateSetting.title') }}
            <i class="tip ml10"
              >({{
                $t('cuttingDock.labelingSettings.rotateSetting.rotateTip')
              }})</i
            >
          </span>
          <span
            class="head-title pt0"
            style="font-weight: 500; font-size: 16px"
          >
            {{ $t('cuttingDock.labelingSettings.settingValue') }}
            <a-switch
              v-model="formData.rotateLabelSetting.rotateLabel"
              size="small"
              id="engraving_setting_rotateLabel_switch"
              class="ml10"
            ></a-switch>
            <i class="tip ml10">
              ({{ $t('cuttingDock.labelingSettings.enabelTip') }})
            </i>
          </span>
          <div
            class="group"
            v-show="
              isShowSecondaryCatalog || formData.rotateLabelSetting.rotateLabel
            "
          >
            <div>
              <a-radio-group
                v-model="formData.rotateLabelSetting.horizontal"
                :disabled="!formData.rotateLabelSetting.rotateLabel"
              >
                <a-radio
                  value="H"
                  id="engraving_setting_rotateLabelSetting_H_radio"
                  ><span
                    id="engraving_setting_rotateLabelSetting_H_radio_label"
                    >{{
                      $t(
                        'cuttingDock.labelingSettings.rotateSetting.horizontal'
                      )
                    }}</span
                  ></a-radio
                >
                <br />
                <a-radio
                  value="V"
                  id="engraving_setting_rotateLabelSetting_V_radio"
                  ><span
                    id="engraving_setting_rotateLabelSetting_V_radio_label"
                    >{{
                      $t('cuttingDock.labelingSettings.rotateSetting.vertical')
                    }}</span
                  ></a-radio
                >
                <br />
                <a-radio
                  value="HV"
                  id="engraving_setting_rotateLabelSetting_HV_radio"
                  ><span
                    id="engraving_setting_rotateLabelSetting_HV_radio_label"
                    >{{
                      $t('cuttingDock.labelingSettings.rotateSetting.either')
                    }}</span
                  ></a-radio
                >
              </a-radio-group>
            </div>
            <el-input
              size="small"
              v-model="formData.rotateLabelSetting.criticalValue"
              @input="
                checkInout(
                  $event,
                  formData,
                  ['rotateLabelSetting', 'criticalValue'],
                  '4-2',
                  '0.01'
                )
              "
              ref="rotateInput"
              id="engraving_setting_rotateLabelSetting_criticalValue_input"
            ></el-input
            >mm
            <img src="@/assets/tagRota.png" alt="加载错误" />
          </div>
          <span
            class="head-title pt0"
            style="font-weight: 500; font-size: 16px"
          >
            {{ $t('cuttingDock.labelingSettings.fixedHori') }}
            <a-switch
              v-model="formData.lable_parallel_setting.parallel_plank"
              size="small"
              id="engraving_setting_rotateLabel_switch"
              class="ml10"
            ></a-switch>
            <i class="tip ml10">
              ({{ $t('cuttingDock.labelingSettings.fixedHoriTip') }})
            </i>
          </span>
          <span
            class="head-title pt0"
            style="font-weight: 500; font-size: 16px"
            v-show="
              isShowSecondaryCatalog ||
              formData.lable_parallel_setting.parallel_plank
            "
          >
            {{ $t('cuttingDock.labelingSettings.tagHori') }} =
            <a-select
              style="width: 180px"
              v-model="formData.lable_parallel_setting.parallel_approach"
              @change="changeLabelingFormat"
              id="engraving_setting_tagHorSide_select"
              class="ml20"
              showArrow
              :options="tagHorSideArr"
              :disabled="!formData.lable_parallel_setting.parallel_plank"
            >
            </a-select>
            <img
              src="@/assets/tagSign.png"
              alt="加载错误"
              style="margin-left: 50px"
            />
          </span>
        </div>
        <div class="XYZ-offset">
          <span class="head-title">{{
            $t('cuttingDock.labelingSettings.countertopSetting.title')
          }}</span>
          <div class="XYZ-offset-box">
            <div class="x-point">
              <span
                >{{
                  $t(
                    'cuttingDock.labelingSettings.countertopSetting.startPoint'
                  )
                }}：</span
              >
              <a-radio-group
                v-model="
                  formData.labelingCountertopSetting.labelingStartPosition
                "
                button-style="solid"
              >
                <a-radio-button
                  value="top_left"
                  id="engraving_setting_labelingStartPosition_TL_radio"
                  >{{
                    $t('cuttingDock.labelingSettings.countertopSetting.upLeft')
                  }}</a-radio-button
                >
                <a-radio-button
                  value="top_right"
                  id="engraving_setting_labelingStartPosition_TR_radio"
                  >{{
                    $t('cuttingDock.labelingSettings.countertopSetting.upRight')
                  }}</a-radio-button
                >
                <a-radio-button
                  value="bottom_left"
                  id="engraving_setting_labelingStartPosition_BL_radio"
                  >{{
                    $t(
                      'cuttingDock.labelingSettings.countertopSetting.downLeft'
                    )
                  }}</a-radio-button
                >
                <a-radio-button
                  value="bottom_right"
                  id="engraving_setting_labelingStartPosition_BR_radio"
                  >{{
                    $t(
                      'cuttingDock.labelingSettings.countertopSetting.downRight'
                    )
                  }}</a-radio-button
                >
              </a-radio-group>
              <span>({{ labelingStartPositionTip }})</span>
            </div>
          </div>
          <div class="XYZ-offset-box">
            <div class="x-point">
              <span
                >{{
                  $t(
                    'cuttingDock.labelingSettings.countertopSetting.baseExchange'
                  )
                }}：</span
              >
              <a-checkbox
                v-model="formData.labelingCountertopSetting.xDatumSwap"
                id="engraving_setting_xDatumSwap_checkbox"
                ><span id="engraving_setting_xDatumSwap_checkbox_label">{{
                  $t('cuttingDock.labelingSettings.countertopSetting.exchangeX')
                }}</span></a-checkbox
              >
              <a-checkbox
                v-model="formData.labelingCountertopSetting.yDatumSwap"
                id="engraving_setting_yDatumSwap_checkbox"
                ><span id="engraving_setting_yDatumSwap_checkbox_label">{{
                  $t('cuttingDock.labelingSettings.countertopSetting.exchangeY')
                }}</span></a-checkbox
              >
            </div>
          </div>
        </div>
        <div class="XYZ-offset">
          <span class="head-title">{{
            $t('cuttingDock.labelingSettings.offset')
          }}</span>
          <div class="XYZ-offset-box">
            <div class="x-point">
              <span>X：</span>
              <a-input
                :placeholder="$t('common.inputPlaceholder')"
                v-model="formData.labelXOffset"
                id="engraving_setting_labelXOffset_input"
              ></a-input>
            </div>
            <div class="y-point">
              <span>Y：</span>
              <a-input
                :placeholder="$t('common.inputPlaceholder')"
                v-model="formData.labelYOffset"
                id="engraving_setting_labelYOffset_input"
              ></a-input>
            </div>
            <span>({{ $t('cuttingDock.labelingSettings.offsetTip') }})</span>
          </div>
        </div>
        <!-- 标签旋转偏移 -->
        <div class="XYZ-offset">
          <div class="flex labeling-dodge">
            <span class="fs18 color-0 bold mr16">{{
              $t('cuttingDock.labelingSettings.label_rotate_offset')
            }}</span>
            <a-switch
              v-model="formData.offsetAfterLabelRotation"
              id="labelling_setting_tagRotateOffset_switch"
            ></a-switch>
            <span class="ml16"
              >({{
                $t('cuttingDock.labelingSettings.label_rotate_offset_tip')
              }})</span
            >
          </div>
          <div
            v-show="formData.offsetAfterLabelRotation"
            class="XYZ-offset-box"
          >
            <div class="x-point">
              <span>X：</span>
              <a-input
                :placeholder="$t('common.inputPlaceholder')"
                v-model="formData.xOffsetAfterLabelRotation"
                id="engraving_setting_xOffsetAfterLabelRotation_input"
              ></a-input>
            </div>
            <div class="y-point">
              <span>Y：</span>
              <a-input
                :placeholder="$t('common.inputPlaceholder')"
                v-model="formData.yOffsetAfterLabelRotation"
                id="engraving_setting_yOffsetAfterLabelRotation_input"
              ></a-input>
            </div>
          </div>
          <span v-show="checkTagRotateOffset" class="err-tip ml16">
            {{ $t('cuttingDock.labelingSettings.label_rotate_offset_err_tip') }}
          </span>
        </div>
        <div class="image-format">
          <span class="head-title">{{
            $t('cuttingDock.labelingSettings.imgSetting.title')
          }}</span>
          <div class="image-format-box">
            <span>{{
              $t('cuttingDock.labelingSettings.imgSetting.suffix')
            }}</span>
            <a-radio-group v-model="formData.labelImageFormat">
              <a-radio
                value="jpg"
                id="engraving_setting_labelImageFormat_jpg_radio"
                ><span id="engraving_setting_labelImageFormat_jpg_radio_label"
                  >jpg</span
                ></a-radio
              >
              <a-radio
                value="bmp"
                id="engraving_setting_labelImageFormat_bmp_radio"
                ><span id="engraving_setting_labelImageFormat_bmp_radio_label"
                  >bmp</span
                ></a-radio
              >
              <a-radio
                value="png"
                id="engraving_setting_labelImageFormat_png_radio"
                ><span id="engraving_setting_labelImageFormat_png_radio_label"
                  >png</span
                ></a-radio
              >
            </a-radio-group>
          </div>
          <div class="image-format-box">
            <span>{{
              $t('cuttingDock.labelingSettings.imgSetting.code')
            }}</span>
            <a-radio-group v-model="formData.labelImageFormatNew">
              <a-radio
                value="png"
                id="engraving_setting_labelImageFormatNew_png_radio"
                ><span
                  id="engraving_setting_labelImageFormatNew_png_radio_label"
                  >png</span
                ></a-radio
              >
              <a-radio
                value="jpeg"
                id="engraving_setting_labelImageFormatNew_jpeg_radio"
                ><span
                  id="engraving_setting_labelImageFormatNew_jpeg_radio_label"
                  >jpeg</span
                ></a-radio
              >
              <a-radio
                value="bmp"
                v-if="isShowBmpRadio"
                id="engraving_setting_labelImageFormatNew_bmp_radio"
                ><span
                  id="engraving_setting_labelImageFormatNew_bmp_radio_label"
                  >bmp（{{
                    $t('cuttingDock.labelingSettings.imgSetting.slowDown')
                  }}）</span
                ></a-radio
              >
            </a-radio-group>
            <span style="color: #f00">{{
              $t('cuttingDock.labelingSettings.imgSetting.codeTip')
            }}</span>
          </div>
          <div v-if="isBmpExport" class="bmp-bit-setting">
            <a-radio-group v-model="formData.bmp_color_style">
              <a-radio :value="0" id="engraving_setting_is1bitBmp_radio_false"
                ><span id="engraving_setting_is1bitBmp_radio_false_label">{{
                  $t('cuttingDock.labelingSettings.imgSetting.color')
                }}</span></a-radio
              >
              <a-radio :value="1" id="engraving_setting_is1bitBmp_radio_true"
                ><span id="engraving_setting_is1bitBmp_radio_true_label">{{
                  $t('cuttingDock.labelingSettings.imgSetting.bit')
                }}</span></a-radio
              >
            </a-radio-group>
          </div>
          <div class="image-format-box">
            <span
              >{{
                $t('cuttingDock.labelingSettings.imgSetting.resolution')
              }}：</span
            >
            <el-input
              :placeholder="
                $t(
                  'cuttingDock.labelingSettings.imgSetting.resolutionPlaceholder'
                )
              "
              size="small"
              v-model="formData.label_resolution[0]"
              @input="resolutionChange($event, 0)"
              maxlength="4"
              id="engraving_setting_label_resolution_input_1"
            ></el-input>
            ×
            <el-input
              :placeholder="
                $t(
                  'cuttingDock.labelingSettings.imgSetting.resolutionPlaceholder'
                )
              "
              size="small"
              v-model="formData.label_resolution[1]"
              @input="resolutionChange($event, 1)"
              maxlength="4"
              id="engraving_setting_label_resolution_input_2"
            ></el-input>
          </div>
          <div>
            <m-tag-lake
              :tag-list="tagList"
              :values="formData.label_image_name_type ?? []"
              @onChange="handleTagChange"
            ></m-tag-lake>
            <div class="image-format-box">
              <span
                >{{
                  $t('cuttingDock.labelingSettings.imgSetting.delimiter')
                }}：</span
              >
              <a-input
                class="split-char ml10 mr5"
                @input="handleinputSplitChar"
                v-model="formData.label_image_name_separator"
              ></a-input>
              <span class="color-a"
                >（
                {{
                  $t('cuttingDock.labelingSettings.imgSetting.inputTip')
                }}）</span
              >
            </div>
            <div class="image-format-box">
              <span
                >{{
                  $t('cuttingDock.labelingSettings.imgSetting.numStart')
                }}:</span
              >
              <a-input
                id="labellingSetting_start_number_input"
                class="split-char ml10 mr5"
                @blur="handleInputBlur"
                @input="handleInputNumber"
                v-model="formData.front_labeling_start_num"
              ></a-input>
            </div>
            <div class="image-format-box">
              <span
                >{{
                  $t('cuttingDock.labelingSettings.imgSetting.example')
                }}:</span
              >
              <span style="white-space: pre">{{ exampleName }}</span>
            </div>
            <div class="image-format-box">
              <span class="title"
                >{{
                  $t('cuttingDock.labelingSettings.imgSetting.divideFolders')
                }}:</span
              >
              <a-checkbox
                id="labellingSetting_big_plank_folder_checkbox"
                class="ml10 mr20"
                v-model="formData.label_img_big_plank_folder"
                :disabled="obj.label_image_in_nc_folder"
              ></a-checkbox>
              <span class="color-a"
                >（{{
                  $t('cuttingDock.labelingSettings.imgSetting.foldersTip1')
                }}</span
              >
              <span style="color: #f00"
                >{{
                  $t('cuttingDock.labelingSettings.imgSetting.foldersTip2')
                }}）
              </span>
            </div>
          </div>
          <div class="image-format-box" v-if="!isHideLabelingInsert">
            <span class="title"
              >{{
                $t('cuttingDock.labelingSettings.imgSetting.stickIntoFolders')
              }}：</span
            >
            <a-checkbox
              class="ml10 mr20"
              id="labellingSetting_file_insert_checkbox"
              :disabled="!formData.label_img_big_plank_folder"
              v-model="formData.labeling_file_insert"
            ></a-checkbox>
            （<span class="color-a">{{
              $t('cuttingDock.labelingSettings.imgSetting.stickTip1') +
              $t('cuttingDock.labelingSettings.imgSetting.stickTip2')
            }}</span>
            <span style="color: #f00"
              >{{
                $t('cuttingDock.labelingSettings.imgSetting.stickTip3')
              }} </span
            >）
          </div>
        </div>
      </div>
      <div class="paiban-image" v-show="formData.labelingMachine">
        <span class="head-title">{{
          $t('cuttingDock.labelingSettings.paibanImgSetting.title')
        }}</span>
        <div class="auto-arrange-umber">
          <span class="title">{{
            $t('cuttingDock.labelingSettings.paibanImgSetting.reparation')
          }}</span>
          <a-input-number
            id="labellingSetting_autoArrangeNumber_input"
            v-model="formData.layoutImageSetting.num_auto_offset"
            :min="1"
            :max="99"
          ></a-input-number>
          <span>{{
            $t('cuttingDock.labelingSettings.paibanImgSetting.figure')
          }}</span>
          <span class="color-a"
            >（{{
              $t('cuttingDock.labelingSettings.paibanImgSetting.inputTip')
            }}）</span
          >
        </div>
        <div class="paiban-image-box">
          <div
            v-for="(item, index) in formData.layoutImageSetting.layoutImage"
            :key="index"
          >
            <div class="paiban-form-item">
              <span class="title"
                >{{
                  $t(
                    'cuttingDock.labelingSettings.paibanImgSetting.paibanImg.export'
                  )
                }}{{ index + 1 }}</span
              >
              <a-checkbox
                v-model="item.enable"
                id="labellingSetting_enable_checkbox"
              ></a-checkbox>
            </div>
            <div v-show="isShowSecondaryCatalog || item.enable">
              <div class="paiban-form-item paiban-before">
                <span class="title">{{
                  $t(
                    'cuttingDock.labelingSettings.paibanImgSetting.paibanImg.prefix'
                  )
                }}</span>
                <a-input
                  v-model="item.imageNamePrefix"
                  :max="20"
                  id="labellingSetting_imageNamePrefix_input"
                ></a-input>
              </div>
              <div class="paiban-form-item paiban-size">
                <span class="title">{{
                  $t(
                    'cuttingDock.labelingSettings.paibanImgSetting.paibanImg.size'
                  )
                }}</span>
                <div>
                  <a-input
                    v-model="item.imageSize[0]"
                    @input="paibanImgLimit(item, 0)"
                    id="labellingSetting_paibanImgLimit0_input"
                  ></a-input>
                  X
                  <a-input
                    v-model="item.imageSize[1]"
                    @input="paibanImgLimit(item, 1)"
                    id="labellingSetting_paibanImgLimit1_input"
                  ></a-input>
                </div>
              </div>
              <div class="paiban-form-item">
                <span class="title">{{
                  $t(
                    'cuttingDock.labelingSettings.paibanImgSetting.paibanImg.format'
                  )
                }}</span>
                <a-radio-group v-model="item.imageFormat">
                  <a-radio
                    value="jpg"
                    id="labellingSetting_imageFormat_jpg_radio"
                    >jpg</a-radio
                  >
                  <a-radio
                    value="bmp"
                    id="labellingSetting_imageFormat_bmp_radio"
                    >bmp</a-radio
                  >
                </a-radio-group>
              </div>
              <div class="paiban-form-item" v-if="item.way != 3">
                <span class="title">{{
                  $t(
                    'cuttingDock.labelingSettings.paibanImgSetting.paibanImg.directional'
                  )
                }}</span>
                <a-radio-group v-model="item.imageDirection">
                  <a-radio
                    value="vert"
                    id="labellingSetting_imageDirection_vert_radio"
                    >{{
                      $t(
                        'cuttingDock.labelingSettings.paibanImgSetting.paibanImg.vertical'
                      )
                    }}</a-radio
                  >
                  <a-radio
                    value="horiz"
                    id="labellingSetting_imageDirection_horiz_radio"
                    >{{
                      $t(
                        'cuttingDock.labelingSettings.paibanImgSetting.paibanImg.horizontal'
                      )
                    }}</a-radio
                  >
                </a-radio-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import newContent from '@/components/newContent.vue'
import { buryPointFunc } from '@/util/buryPointDeal'
import { verifyInputNumber } from '@/util/commonFuncs'
import { mapState } from 'vuex'

import MTagLake from './m-tag-lake.vue'

export default {
  components: {
    MTagLake,
    newContent,
  },
  props: {
    obj: {
      type: [Array, Object],
      default: () => ({}),
    },
    paibanImgScale: {
      type: Number,
      default: 2,
    },
    allLabelingList: {
      type: [Array, Object],
      default: () => [],
    },
    labelNotOnline: {
      type: Boolean,
      default: false,
    },
    isShowSecondaryCatalog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      vv: true,
      fileFormatList: [
        { name: '默认格式', value: 'bluen' },
        { name: '南兴格式', value: 'nanxing' },
        { name: '霍尼韦尔格式', value: 'honeywell' },
        { name: '星辉格式', value: 'xinghui' },
        { name: '豪德格式', value: 'haode' },
        { name: '极东格式', value: 'jidong' },
        { name: '铭雕格式', value: 'mingdiao' },
        { name: '精美格式', value: 'jingmei' },
      ],
      formData: {
        offsetAfterLabelRotation: false,
        xOffsetAfterLabelRotation: '',
        yOffsetAfterLabelRotation: '',
        labelingDodge: true,
        labelingOrder: 'cutOrder',
        label_after_send_stock: false,
        labelOverturn: false,
        labelFormat: '',
        labelXOffset: '',
        labelYOffset: '',
        labelingMachine: true,
        labelImageFormat: '',
        labelImageFormatNew: '',
        label_resolution: [],
        front_labeling_start_num: '0', //贴标序号起始数值
        label_img_big_plank_folder: false, // 标签图片根据大板分为不同文件夹
        labeling_file_insert: false, // 贴标文件插入到对应大板的贴标图片文件夹中
        label_picture_delimiter: false,
        labelingCountertopSetting: {
          labelingStartPosition: 'bottom_left',
          xDatumSwap: false,
          yDatumSwap: false,
        },
        layoutImageSetting: {
          num_auto_offset: 1,
          layoutImage: [
            {
              enable: false,
              imageDirection: 'horiz',
              imageFormat: 'bmp',
              imageNamePrefix: 'T',
              imageSize: [158, 107],
              way: 1,
            },
            {
              enable: false,
              imageDirection: 'horiz',
              imageFormat: 'bmp',
              imageNamePrefix: 'T',
              imageSize: [158, 107],
              way: 2,
            },
            {
              enable: false,
              imageDirection: 'vert',
              imageFormat: 'bmp',
              imageNamePrefix: 'T',
              imageSize: [793, 1122],
              way: 3,
            },
          ],
        },
        rotateLabelSetting: {
          criticalValue: 80,
          horizontal: 'H',
          rotateLabel: false,
        },
        label_image_name_type: [], // 字段同步后端命名
        label_image_name_separator: '_', //贴标文件分隔符
        bmp_color_style: 0, //是否为1bit导出
        lable_parallel_setting: {
          parallel_plank: false,
          parallel_approach: 'parallel_height',
        },
        // 后端使用数量字段要求
        old_label_format: '',
      },
      tagList: [],
      defaulTagValue: {
        address: '订单一',
        orderNo: '220818-154',
        nc_filename: '001_多层实木_T01_18',
        labeling_filename: 'Label_001_多层实木_T01_18',
        plankNum: '6126134567891',
        stockNum: '1',
        priority: '1',
        labelId: '001',
        auto_index: '0',
      },
      isHideLabelingInsert: false, // 根据是否是默认模板展示
      autoArrangeError: '',
      tagHorSideArr: [
        { label: '与小板长度保持平行', value: 'parallel_height' },
        { label: '与小板宽度保持平行', value: 'parallel_width' },
      ],
    }
  },
  watch: {
    'formData.layoutImageSetting.num_auto_offset': {
      handler(newVal) {
        if (!newVal) {
          this.formData.layoutImageSetting.num_auto_offset = 1
        }
      },
      deep: true,
      immediate: true,
    },
    obj() {
      if (Object.keys(this.obj).length > 0) {
        this.formData = { ...this.obj, old_label_format: this.obj.labelFormat }
      }
      if (this.obj.label_image_in_nc_folder) {
        this.formData.label_img_big_plank_folder = false
      }
    },
    'formData.labelImageFormat': {
      handler(newVal) {
        // 在贴标图片后缀为jpg，图片编码格式为bmp时，默认将图片编码选择为png
        if (newVal === 'jpg' && this.formData.labelImageFormatNew === 'bmp') {
          this.formData.labelImageFormatNew = 'png'
        }
      },
      immediate: true,
    },
    ncLabel: {
      handler(val) {
        this.tagList = [
          {
            title: '',
            arr: val.filter((v) => v.label_type == 'label_image_info'),
          },
        ]
      },
    },
    'formData.labelFormat': {
      handler(val) {
        const info = this.allLabelingList.find(
          (item) => item.labelFormat === val
        )
        // 是默认模板
        if (info && info.default_model === 1) {
          this.isHideLabelingInsert = true
          this.labeling_file_insert = false
        } else {
          this.isHideLabelingInsert = false
        }
      },
    },
    'formData.front_labeling_start_num': {
      handler(newVal) {
        if (
          this.formData.label_image_name_type.filter((i) => i === 'auto_index')
            .length
        ) {
          this.defaulTagValue.auto_index = newVal
        }
      },
      immediate: true,
    },
    'formData.yOffsetAfterLabelRotation': {
      handler(val) {
        if (val == 0) {
          this.formData.yOffsetAfterLabelRotation = ''
        }
      },
    },
    'formData.xOffsetAfterLabelRotation': {
      handler(val) {
        if (val == 0) {
          this.formData.xOffsetAfterLabelRotation = ''
        }
      },
    },
  },
  computed: {
    ...mapState(['ncLabel']),
    ...mapState('equipEnvironment', ['previewInfo']),
    labelingStartPositionTip() {
      let label = this.formData.labelingCountertopSetting.labelingStartPosition
      let tip = ''
      switch (label) {
        case 'top_left':
          tip = '+X-Y'
          break
        case 'top_right':
          tip = '-X-Y'
          break
        case 'bottom_left':
          tip = '+X+Y'
          break
        case 'bottom_right':
          tip = '-X+Y'
          break
      }
      return tip
    },
    // 生成示例
    exampleName() {
      let str = this.formData.label_image_name_type
        ? Array.from(this.formData.label_image_name_type)
            .map((val) => {
              return this.defaulTagValue[val]
            })
            .join(this.formData.label_image_name_separator)
        : ''
      return str
    },
    // 是否导出bmp
    isBmpExport() {
      return (
        this.formData.labelImageFormat == 'bmp' &&
        this.formData.labelImageFormatNew == 'bmp'
      )
    },
    // 标签旋转后偏移必填检测
    checkTagRotateOffset() {
      return (
        this.formData.offsetAfterLabelRotation &&
        (!this.formData.xOffsetAfterLabelRotation ||
          !this.formData.yOffsetAfterLabelRotation)
      )
    },
    isShowBmpRadio() {
      return this.formData.labelImageFormat === 'bmp'
    },
  },
  methods: {
    // 切换选择
    handleChangeRadio(e) {
      let val = e.target.value
      if (val == 'toTop') {
        buryPointFunc(
          '雕刻机-贴标设置-自动贴标顺序设置-按照排版结果从下往上贴标'
        )
      } else if (val == 'toBottom') {
        buryPointFunc(
          '雕刻机-贴标设置-自动贴标顺序设置-按照排版结果从上往下贴标'
        )
      } else {
        buryPointFunc('雕刻机-贴标设置-自动贴标顺序设置-按照开料顺序贴标')
      }
    },
    // 标签池拖动改变
    handleTagChange(values) {
      this.formData.label_image_name_type = values
    },
    // 输入分隔符
    handleinputSplitChar() {
      // 限制输入特殊符号
      const reg = /[\\<>:|./?*"]/g
      this.formData.label_image_name_separator =
        this.formData.label_image_name_separator.replace(reg, '')
      if (this.formData.label_image_name_separator.length > 10) {
        this.formData.label_image_name_separator =
          this.formData.label_image_name_separator.slice(0, 10)
      }
    },
    // 输入贴标序号起始数值
    handleInputNumber() {
      const reg = /[^0-9]/g
      this.formData.front_labeling_start_num =
        this.formData.front_labeling_start_num.replace(reg, '')
      if (this.formData.front_labeling_start_num.length > 10) {
        this.formData.front_labeling_start_num =
          this.formData.front_labeling_start_num.slice(0, 10)
      }
    },
    // 贴标序号起始数值鼠标失去光标
    handleInputBlur() {
      if (!this.formData.front_labeling_start_num) {
        this.formData.front_labeling_start_num = '0'
      }
    },
    // index为1的时候为宽的值
    paibanImgLimit(item, index) {
      item.imageSize[index] = Number(
        item.imageSize[index].replace(/^(0+)|[^\d]+/g, '')
      )
    },
    // 修改贴标机模板时
    changeLabelingFormat() {
      if (this.formData.labelFormat != '' && this.labelNotOnline) {
        this.$emit('update:labelNotOnline', false)
      }
    },
    resolutionChange(val, key) {
      this.formData.label_resolution[key] = val.replace(/[^\d]/g, '')
    },
    checkInout(val, obj, keys, rule, min) {
      verifyInputNumber(val, obj, keys, rule, false, min)
    },
    // 当旋转生效输入框未输入值时激活输入框
    focusInput() {
      this.$refs['rotateInput'].focus()
    },
  },
  mounted() {
    this.focusInput()
  },
}
</script>

<style scoped lang="less">
.labelling-setting {
  .head-title {
    display: flex;
    align-items: center;
    background: #fff;
    span {
      margin-right: 16px;
      font-weight: 400;
      font-size: 20px;
    }
  }
  .labeling-setting {
    border-bottom: 0px;
  }
  .label-file-format {
    background: #fff;
    .radio-box {
      padding: 24px 24px 16px 24px;
      .all-labeling {
        z-index: 4;
      }
    }
    .not-online-info {
      margin-left: 16px;
      color: #333;
      color: #f00;
      font-size: 14px;
    }
  }
  .auto-feeding-box {
    padding: 24px 24px 16px 24px;
    background-color: #fff;
    .tip {
      color: #949494;
    }
  }
  .auto-label-setting {
    margin-top: 24px;
    .title {
      padding: 24px;
    }
    .auto-label-tip {
      line-height: 31px;
    }
  }
  .tag-setting {
    background: #fff;
    .head-title {
      .tip {
        margin-right: 10px;
        color: rgb(148, 148, 148);
        font-weight: 400;
        font-size: 13px;
        font-style: normal;
      }
    }
    .group {
      display: flex;
      align-items: center;
      padding: 24px 24px 16px 24px;
      .el-input {
        width: 100px;
        margin: 0 10px;
      }
      &/deep/.ant-radio-group {
        margin: 0;
      }
      img {
        margin: 0 40px;
      }
    }
  }
  .XYZ-offset {
    background: #fff;
    .XYZ-offset-box {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 24px;
      & > span {
        color: #8c8c8c;
        font-size: 14px;
      }
      span {
        cursor: default;
      }
      .ant-input {
        width: 83px;
      }
      .x-point {
        margin-right: 40px;
      }
      .y-point {
        margin-right: 24px;
      }
    }
  }
  .image-format {
    background: #fff;
    .image-format-box {
      padding: 24px;
      .el-input,
      .split-char {
        width: 100px;
      }
    }
    .bmp-bit-setting {
      padding-left: 30px;
      :deep(.ant-radio-group) {
        .ant-radio-wrapper {
          span:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }
  .paiban-image {
    background: #fff;
    .paiban-image-box {
      display: flex;
      flex-wrap: wrap;
      padding: 24px;
      > div {
        margin-right: 96px;
      }
    }
    .paiban-form-item {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      .title {
        display: inline-block;
        flex-shrink: 0;
        width: 92px;
        margin-right: 16px;
        text-align: left;
      }
    }
    .paiban-size {
      .ant-input {
        width: 64px;
      }
    }
  }
  .err-tip {
    top: 45px;
    left: 140px;
    color: red;
  }
}
.auto-arrange-umber {
  padding: 0 24px;
}
.labeling-dodge {
  align-items: center;
  padding: 24px;
}
#labellingSetting_autoArrangeNumber_input {
  width: 64px !important;
}
</style>
