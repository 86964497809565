// 单双工位单选配置项
export const cuttingDockStationSetup = [
  {
    label: 'cuttingDock.knives.singleStation',
    value: 'single',
  },
  {
    label: 'cuttingDock.knives.doubleStation',
    value: 'double',
  },
]
